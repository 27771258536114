import forEach from 'lodash/forEach';
import map from 'lodash/map';
import values from 'lodash/values';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { tokenizer } from '../../../../shared/filters-compiler/tokenizer';
import { setCmdPaletteOpen } from '../../../../shared/foreground/cmdPalette';
import { globalState } from '../../../../shared/foreground/models';
import {
  useDocumentTagsAsObject,
  useGlobalTagsAsObject,
} from '../../../../shared/foreground/stateHooks';
import { useFocusedDocumentId } from '../../../../shared/foreground/stateHooks/useFocusedDocument';
import {
  addTag,
  removeTag,
  renameTag,
} from '../../../../shared/foreground/stateUpdaters/persistentStateUpdaters/documents/tag';
import { createToast } from '../../../../shared/foreground/toasts.platform';
import { needsToMergeTags } from '../../../../shared/foreground/utils/needsToMergeTags';
import type { BaseDocument } from '../../../../shared/types';
import type { GlobalTagsObject } from '../../../../shared/types/tags';
import { cleanAndValidateTagName } from '../../../../shared/utils/cleanAndValidateTagName';
import urlJoin from '../../../../shared/utils/urlJoin';
import { getFilterViewQueryFromPathname } from '../../utils/pathnameHelpers';
import useLocation from '../../utils/useLocation';
import { Dialog } from '../Dialog';
import Tag from '../Tag';
import { PaletteAction } from './Base/PaletteAction';
import { CmdInputContext, PaletteWrapper } from './Base/PaletteWrapper';

const AddNewTagAction = ({
  focused,
  docId,
  globalTagsObject,
}: PaletteAction & { docId: BaseDocument['id'] | null; globalTagsObject: GlobalTagsObject }) => {
  const { input } = useContext(CmdInputContext);

  const action = useMemo(
    () => async () => {
      if (!docId || input === '') {
        return;
      }
      await addTag(docId, input, { userInteraction: 'unknown' });
    },
    [input, docId],
  );

  if (input.toLowerCase() in globalTagsObject) {
    return null;
  }

  return (
    <PaletteAction focused={focused} action={action}>
      Create tag: {input}
    </PaletteAction>
  );
};

const AddExistingTagAction = ({
  focused,
  docId,
  tagName,
  count,
}: PaletteAction & { docId: BaseDocument['id'] | null; count: number; tagName: string }) => {
  const action = useMemo(
    () => async () => {
      if (!docId || tagName === '') {
        return;
      }
      await addTag(docId, tagName, { userInteraction: 'unknown' });
    },
    [tagName, docId],
  );

  return <PaletteAction focused={focused} action={action}>{`${tagName} (${count})`}</PaletteAction>;
};

const DeleteTagAction = React.memo(function DeleteTagAction({
  docId,
  focused,
  lastTag = false,
  name,
}: PaletteAction & { docId: BaseDocument['id'] | null; lastTag: boolean; name: string }) {
  const action = async () => {
    if (!docId) {
      return;
    }
    await removeTag(docId, name, { userInteraction: 'unknown' });
  };
  return (
    <PaletteAction focused={focused} action={action}>
      Remove tag: {name}
    </PaletteAction>
  );
});

const ExistingTagsContainer = ({
  docId,
  documentTagsObject,
}: { docId: string; documentTagsObject: BaseDocument['tags'] }) => {
  const deleteTag = useCallback(
    (name: string) => {
      removeTag(docId, name, { userInteraction: 'click' });
    },
    [docId],
  );

  return (
    <div>
      {map(documentTagsObject, ({ name }) => (
        <Link className="tagItem" to={`/filter/tag:"${encodeURIComponent(name)}"`} key={name}>
          <Tag onClickRemoveIcon={() => deleteTag(name)}>{name}</Tag>
        </Link>
      ))}
    </div>
  );
};

export const TagsPalette = (): JSX.Element => {
  const docId = useFocusedDocumentId();
  const [documentTagsObject] = useDocumentTagsAsObject(docId);

  const [globalTagsObject] = useGlobalTagsAsObject();

  const globalTagNames: GlobalTagsObject = useMemo(() => {
    const tagNames = {};
    forEach(globalTagsObject, (value, key) => {
      if (key in documentTagsObject) {
        return;
      }
      tagNames[key] = value;
    });
    return tagNames;
  }, [documentTagsObject, globalTagsObject]);

  const tags = values(documentTagsObject);
  return (
    <PaletteWrapper
      title="Add Tags"
      placeholder="Start typing your tag name..."
      customComponent={
        Object.keys(documentTagsObject).length > 0 &&
        docId && <ExistingTagsContainer docId={docId} documentTagsObject={documentTagsObject} />
      }
    >
      {map(globalTagNames, (tag, key) => {
        return (
          <AddExistingTagAction
            key={key}
            focused={false}
            docId={docId}
            tagName={tag.name}
            count={tag.totalCount}
            label={key}
          />
        );
      })}
      <AddNewTagAction key="new-tag" docId={docId} focused={false} globalTagsObject={globalTagsObject} />
      {tags.map((t) => {
        return (
          <DeleteTagAction
            key={t.name}
            focused={false}
            name={t.name}
            docId={docId}
            label={t.name}
            lastTag={tags.length === 1}
          />
        );
      })}
    </PaletteWrapper>
  );
};

const EditTagNameAction = ({ focused }: PaletteAction) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [tagToMerge, setTagToMerge] = useState('');
  const { input, setInput } = useContext(CmdInputContext);
  const [globalTagsObject] = useGlobalTagsAsObject();
  const focusedTagId = globalState(useCallback((state) => state.focusedTagId || '', []));
  const tagName = globalTagsObject[focusedTagId.toLocaleLowerCase()]?.name;

  useEffect(() => {
    setInput(tagName || '');
  }, [setInput, tagName]);

  const moveToNewView = useCallback(
    (prevTagName: string, newTagName: string) => {
      // If we are filtering by one tag and renamed the tag, make sure to redirect
      // to the new view. If not, we end up in a view that doesn't exist anymore
      // because the tag renaming logic replaced `tag:oldTag` with `tag:newTag`
      const query = getFilterViewQueryFromPathname(pathname) || '';
      const { tokens } = tokenizer(query);
      const isFilteringByOneTag = Boolean(tokens && tokens.length === 3 && tokens[0].value === 'tag');

      if (isFilteringByOneTag) {
        history.push(urlJoin(['/filter', encodeURIComponent(query.replace(prevTagName, newTagName))]));
      }
    },
    [history, pathname],
  );

  const action = useMemo(
    () => async () => {
      if (input === '') {
        return;
      }

      const prevTagName = tagName;

      const { cleanTagName: newTagName, validationError } = cleanAndValidateTagName(input);

      if (validationError) {
        createToast({
          category: 'error',
          content: validationError.message,
        });
        return;
      }

      if (needsToMergeTags({ globalTagsObject, prevTagName, newTagName })) {
        setTagToMerge(newTagName);
      } else {
        renameTag({ prevTagName, newTagName, options: { userInteraction: 'click' } });
        await setCmdPaletteOpen(false, { userInteraction: 'keypress' });
        moveToNewView(prevTagName, newTagName);
      }
    },
    [input, tagName, globalTagsObject, moveToNewView],
  );

  const onMergeTags = () => {
    renameTag({
      prevTagName: tagName,
      newTagName: tagToMerge,
      options: { userInteraction: 'click' },
    });
    setCmdPaletteOpen(false, { userInteraction: 'keypress' });
    moveToNewView(tagName, tagToMerge);
  };

  return (
    <>
      <PaletteAction focused={focused} action={action}>
        Save changes
      </PaletteAction>
      {Boolean(tagToMerge) && (
        <Dialog
          id="merge-tags"
          title="Merge tags?"
          subtitle={`Are you sure you want to merge the tag "${tagName}" into the existing "${tagToMerge}" tag?`}
          actionTitle="Merge"
          cancelTitle="Cancel"
          redActionButton
          action={onMergeTags}
          cancelAction={() => setTagToMerge('')}
        />
      )}
    </>
  );
};

export const EditTagNamePalette = () => {
  return (
    <PaletteWrapper title="Edit Tag" placeholder="Tag name">
      <EditTagNameAction focused={false} />
    </PaletteWrapper>
  );
};
